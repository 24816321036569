(function () {
    'use strict'

    const offcanvas = document.querySelectorAll(".collapseNavBar");

    for (let i = 0; i < offcanvas.length; i++) {
        offcanvas[i].addEventListener("click", function () {
            document.querySelector('.offcanvas-collapse').classList.toggle('open');
            document.querySelector('.offcanvas-fundo').classList.toggle('open');
            document.querySelector('body').classList.toggle('open');
            document.querySelector('html').classList.toggle('open')
        });
    }
})()