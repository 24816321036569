const btn = document.querySelectorAll('.modal-btn');
const dialogs = document.querySelectorAll('.modal-dialog');
const filter = document.querySelectorAll('.filter');
const modal = document.querySelectorAll('.modal');

if (btn.length != 0) {
    btn.forEach(e => {
        e.addEventListener('click', () => {
            document.querySelector('html').classList.toggle("overflow-hidden");
        });
    });

    dialogs.forEach(dialog => {
        dialog.addEventListener("click", e => {
            e.stopPropagation();
        })
    });

    if (filter.length > 0) {
        window.onresize = () => {
            if (window.innerWidth > 760) {
                modal.style.display = "block";
            }

            if (window.innerWidth < 760) {
                modal.style.display = "none";
            }
        }
    }
}

