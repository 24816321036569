window.addEventListener('load', function () {
    const nav = document.querySelector('.nav-home');

    const footer = document.querySelector('footer');
    const btn = document.querySelector('.btn-scrollup');
    const btn_whatsapp = document.querySelector('.btn-whatsapp');
    const btn_call = document.querySelector('.btn-call');

    if (nav != null) {
        let topoNav = nav.offsetTop;
        window.onresize = () => {
            topoNav = nav.offsetTop;
            fixarMenuNoTopo();
            if (window.innerWidth > 800) {
                nav.classList.remove('fixed-top');
                nav.classList.remove('bg-menu');
            }
        }

        window.onscroll = () => {
            fixarMenuNoTopo();

            // if (footer.getBoundingClientRect().y < window.innerHeight) {
            //     btn_call.classList.add('btn-call-footer');
            //     btn_whatsapp.classList.add('btn-whatsapp-footer');
            //     btn.classList.remove('d-none');
            // } else {
            //     btn.classList.add('d-none');
            //     btn_whatsapp.classList.remove('btn-whatsapp-footer');
            //     btn_call.classList.remove('btn-call-footer');
            // }
        }

        function fixarMenuNoTopo() {
            if (window.pageYOffset >= topoNav) {
                nav.classList.add('fixed-top');
                nav.classList.add('bg-menu');
            } else {
                nav.classList.remove('fixed-top');
                nav.classList.remove('bg-menu');
            }
        }
    } else {
        // window.onscroll = () => {
        //     if (footer.getBoundingClientRect().y < window.innerHeight) {
        //         btn_call.classList.add('btn-call-footer');
        //         btn_whatsapp.classList.add('btn-whatsapp-footer');
        //         btn.classList.remove('d-none');
        //     } else {
        //         btn.classList.add('d-none');
        //         btn_whatsapp.classList.remove('btn-whatsapp-footer');
        //         btn_call.classList.remove('btn-call-footer');
        //     }
        // }
    }

    btn.addEventListener('click', e => {
        e.preventDefault();
        window.scroll(0, 0)
    })
});