const sliders = document.querySelectorAll('.slider');
let marginLeft = [];
let count = [];
let qtd_itens = [];

sliders.forEach((e, key) => {
    let conteudo = document.querySelector('#' + e.getAttribute('data-slider') + ' .items');
    let items = document.querySelectorAll('#' + e.getAttribute('data-slider') + ' .items .item');
    let largura_base = items[0].offsetWidth;
    let largura = items[0].offsetWidth;
    let margin = parseInt(getComputedStyle(items[0]).marginRight);
    let next = document.querySelector('#' + e.getAttribute('data-slider') + ' .next');
    let prev = document.querySelector('#' + e.getAttribute('data-slider') + ' .prev');
    qtd_itens[key] = parseInt(document.querySelector('#' + e.getAttribute('data-slider')).offsetWidth / (largura + margin));
    marginLeft[key] = 0;
    count[key] = 1;

    if (e.getAttribute('data-carousel')) {
        definir_largura();

        window.onresize = () => {
            definir_largura();
        }

        function definir_largura() {
            if (window.innerWidth > (largura_base * 3) + (16 * 2)) {
                carousel_largura = document.querySelector('#' + e.getAttribute('data-carousel')).offsetWidth;

                largura = (carousel_largura - margin * 3) / 4;

                items.forEach(element => {
                    element.style.width = largura + "px";
                });
            }
            if (window.innerWidth < (largura_base * 3) + (16 * 2)) {
                carousel_largura = document.querySelector('#' + e.getAttribute('data-carousel')).offsetWidth;

                largura = (carousel_largura - margin * 2) / 3;

                items.forEach(element => {
                    element.style.width = largura + "px";
                });
            }
        }
    } else {
        count[key] = 0;
    }


    if (e.getAttribute('data-slider') == 'slider-produto') {

        if (count[key] == 1) {
            prev.style.display = 'none';
        } else {
            prev.style.display = 'block';
        }
    } else {
        if (count[key] == 0) {
            prev.style.display = 'none';
        } else {
            prev.style.display = 'block';
        }
    }

    if (count[key] == items.length - qtd_itens[key] || items.length <= qtd_itens[key]) {
        next.style.display = 'none';
    } else {
        next.style.display = 'block';
    }



    next.addEventListener("click", function () {

        qtd_itens[key] = parseInt(document.querySelector('#' + e.getAttribute('data-slider')).offsetWidth / (largura + margin));

        if (count[key] < items.length - qtd_itens[key]) {
            marginLeft[key] = marginLeft[key] - (largura + margin);
            conteudo.style.marginLeft = marginLeft[key] + "px";
            count[key]++;
        }

        if (count[key] == items.length - qtd_itens[key] || items.length <= qtd_itens[key]) {
            next.style.display = 'none';
        } else {
            next.style.display = 'block';
        }

        if (count[key] == 0) {
            prev.style.display = 'none';
        } else {
            prev.style.display = 'block';
        }
    });

    prev.addEventListener("click", function () {
        if (marginLeft[key] < -largura) {
            marginLeft[key] = marginLeft[key] + (largura + margin);
            conteudo.style.marginLeft = marginLeft[key] + "px";
            count[key]--;
        }

        if (count[key] == 0) {
            prev.style.display = 'none';
        } else {
            prev.style.display = 'block';
        }

        if (qtd_itens[key] == 1) {
            qtd_itens[key] = 0
        }

        if (count[key] == items.length - qtd_itens[key] || items.length <= qtd_itens[key]) {
            next.style.display = 'none';
        } else {
            next.style.display = 'block';
        }

    });
});