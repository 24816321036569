const next = document.querySelector('.btn-aba-next');
const prev = document.querySelector('.btn-aba-prev');
const form = document.querySelector('#container-forms');
const form_peca = document.querySelector('#peca');
const form_veiculo = document.querySelector('#veiculo');
const btn_search = document.querySelector('.btn-search');
const menu_search = document.querySelector('.menu-search');
const close = document.querySelectorAll('.form-close');

if (next != null && prev != null && form != null && form_peca != null && form_veiculo != null && btn_search != null && menu_search != null && close != null) {
    btn_search.addEventListener("click", () => {
        menu_search.style.top = 0;
        menu_search.style.opacity = 1;
    });

    next.addEventListener("click", () => {
        form.scrollBy(window.innerWidth, 0);
        next.classList.add('active');
        prev.classList.remove('active');
    });

    prev.addEventListener("click", () => {
        form.scrollBy(-window.innerWidth, 0);
        prev.classList.add('active');
        next.classList.remove('active');
    });

    close.forEach(e => {
        e.addEventListener("click", () => {
            menu_search.style.opacity = 0;
            setTimeout(() => {
                menu_search.style.top = "-320px";
            }, 1000);
        });
    });
}